/* global google */
import React, { Component } from 'react';
import { GoogleMap, LoadScript, MarkerF, PolylineF, PolygonF, Polygon,  useJsApiLoader, Circle, InfoBox } from '@react-google-maps/api';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

import IconButton from "@mui/material/IconButton";
import Stack from '@mui/material/Stack';
import { createRoot } from 'react-dom/client';

class DrawPath extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ob_coordinates: [{
        lat: 0,
        lng: 0
      }],
      ib_coordinates: [{
        lat: 0,
        lng: 0
      }],      
      currentLocation: {
        lat: 0,
        lng: 0
      },
      mapRef: null,
      watch_id: null,
      ob_capture: 0,
      ib_capture: 0,
      ob_done:0,
      ib_done:0,
      ib_index:0
    }
  }
 
  componentDidMount = async () => {
    // On Page Reload -> fetch current location
    const { lat, lng } = await this.getcurrentLocation();
    this.setState({
      currentLocation: {
        lat,
        lng
      },
      ob_coordinates: [],     
      ib_coordinates: []
    }, () => {
      console.log("OnMount", this.state.ob_coordinates, this.state.currentLocation);
    })
  }

  // Watch position function options for a precised location capture.
  options_current_location = {
    enableHighAccuracy: true,
    timeout: 15000,
    maximumAge: 0
  };

  getcurrentLocation() {
    if (navigator && navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
          const coords = position.coords;
          resolve({
            lat: coords.latitude,
            lng: coords.longitude
          });
        },
          (error) => {
            console.log(error.code, error.message);
          },
          this.options_current_location
        );
      });
    }
    return {
      lat: 0,
      lng: 0
    };
  }
  
  reset_state() {
    this.setState({
      currentLocation: this.state.currentLocation,      
      ob_coordinates: [],
      ib_coordinates: [], 
    }, () => {
      console.log("Reset State", this.state.ob_coordinates, this.state.ib_coordinates , this.state.currentLocation);
    })
  }

  reset() {
    this.reset_state();
    this.componentDidMount();    
    this.ob_capture= 0;
    this.ib_capture= 0;
    this.ob_done = 0 ;
    this.ib_done = 0 ;
  }

  HandleIBButtonClick = (flag = 0) => {
    if (flag === 0) {   
      this.ib_capture = 1;     
      console.log("DrawPath IB is ON", this.state.ib_coordinates);         
    } else {     
      console.log("DrawPath IB is OFF", this.state.ib_coordinates);              
      this.ib_capture = 0;      
      if (this.state.ib_coordinates.length)   {
        this.ib_done = 1;
      }         
    }
    this.UpdateMapControls(this.state.mapRef);
  }

  HandleOBButtonClick = (flag = 0) => {
    if (flag === 0) {   
      this.ob_capture = 1;
      console.log("DrawPath OB is ON", this.state.ob_coordinates);
      // Clear co-ordinates list to start
      this.state.ob_coordinates = [];   
    } else if (flag === 2) {
      this.reset();     
    } else {
      console.log("DrawPath OB is Off", this.state.ob_coordinates);     
      this.ob_capture = 0;
      if (this.state.ob_coordinates.length)   {
        this.ob_done = 1;
      }      
    }
    this.UpdateMapControls(this.state.mapRef);
  }

  UpdateMapControls(map) {
    console.log("UpdateMapControls called");
    const iconStyle = {   
      width:'60px', 
      height:'60px',
      color:'#F79023'
    }
    const disabledIconStyle = {   
      width:'60px', 
      height:'60px',
      color:'#808080'
    }

    const buttonStyle = {   
      padding:'0px'    
    }

    var controlButtonsDiv = document.getElementById('ButtonsDiv');
    if(controlButtonsDiv != null) {
      map.controls[google.maps.ControlPosition.BOTTOM_CENTER].clear();
    }
    controlButtonsDiv = document.createElement('div');     
    controlButtonsDiv.setAttribute("id", "ButtonsDiv");      
    
    const root = createRoot(controlButtonsDiv); 
    root.render(    
      <Stack direction="row" spacing={1} paddingBottom="30px">    
        <Tooltip title="Outer boundary" placement="top">
          <span>
            <IconButton 
              onClick={() => this.ob_capture? this.HandleOBButtonClick(1): this.HandleOBButtonClick(0)}  
              style={buttonStyle}
              disabled={this.ob_done? true: false}
            >
              {this.ob_capture ?
                <StopCircleOutlinedIcon color="primary"  style={iconStyle} /> :
                <PlayCircleOutlineIcon color="primary"  style={this.ob_done? disabledIconStyle:iconStyle} />
              }
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Inner boundary" placement="top">
          <span>
            <IconButton onClick={() => this.ib_capture? this.HandleIBButtonClick(1) : this.HandleIBButtonClick(0)} 
              style={buttonStyle} 
              disabled={this.ob_done && !this.ib_done ? false: true} 
            >
              {this.ib_capture ?
                <StopCircleOutlinedIcon color="primary"  style={iconStyle} /> :
                <PlayCircleOutlineIcon color="primary"  style={this.ob_done && !this.ib_done ? iconStyle:disabledIconStyle} />
              }           
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Reset" placement="top">
          <span>
            <IconButton onClick={() => this.HandleOBButtonClick(2)} style={buttonStyle} disabled={this.ob_capture?true:false}>
              <RestartAltIcon color="primary"  style={this.ob_capture ? disabledIconStyle : iconStyle} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Save" placement="top">
          <span>
            <IconButton 
              onClick={() => console.log('Saving land parcel boundaries')} 
              style={buttonStyle} 
              disabled={this.ob_capture || !this.ob_done ? true : false }
            >
              <SaveIcon color="primary"  style={this.ob_capture || !this.ob_done ? disabledIconStyle : iconStyle} />
            </IconButton> 
          </span>      
        </Tooltip>
        <Tooltip title="Close" placement="top">      
          <IconButton onClick={() => console.log('Closing boundaries definition window')} style={buttonStyle} >
            <CloseIcon color="primary"  style={iconStyle} />
          </IconButton>        
        </Tooltip>
      </Stack>
    );
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(controlButtonsDiv);
  } 

  render() {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyDLXNnFU8g68zfD6atZUz83wMmFrknfncg"
      >        
        <GoogleMap
          center={this.state.currentLocation}
          onLoad={(map) => {
            map.setMapTypeId("satellite");
            this.setState({ mapRef: map }, () => {
              console.log("onLoad", map);
            });
            this.UpdateMapControls(map);          
          }}

          onClick={ev => {
            console.log("latitide = ", ev.latLng.lat());
            console.log("longitude = ", ev.latLng.lng());
            const lat = ev.latLng.lat();
            const lng = ev.latLng.lng();
            if (this.ob_capture === 1) {
              this.setState(
              {
                ob_coordinates: [
                  ...this.state.ob_coordinates,
                  {
                    lat,
                    lng
                  }
                ]
              }, () => {
                console.log("Onclick OB is ON", this.state.ob_coordinates);
              }
              )
            } else if (this.ib_capture === 1)  {
              
              this.setState({
                ib_coordinates: [
                  ...this.state.ib_coordinates,
                  {
                    lat,
                    lng
                  }
                ]
              }, () => {
                console.log("Onclick IB is ON", this.state.ib_coordinates);
              })              
              
            }      
              
    
          }}          
          zoom={20}          
          ref={this.map}
          mapContainerStyle={{ 
            width: "100%", 
            height: "100vh"
          }}
          options={{
            //draggableCursor:'crosshair',
            //draggingCursor:'crosshair'
            // zoomControl: false,
            // streetViewControl: false,
            // mapTypeControl: false,
            // fullscreenControl: true,
            //disableDefaultUI: true
          }}   
        >         
          
          {(this.ob_capture === 1 || this.ob_done === 1) && this.state.ob_coordinates.map((mark, index) => (

            <MarkerF
              icon={{
                path: "m -3, 0 a 3,3 0 1,0 6,0 a 3,3 0 1,0 -6,0",
                scale: 2,
                fillColor: "red",
                fillOpacity: 1.0,
                strokeColor: "gold",
                strokeWeight: 3,
              }}
              key={index}
              position={mark}
            />
          ))}

          {(this.ib_capture === 1 || this.ib_done === 1)  && this.state.ib_coordinates.map((mark, index) => (
            <MarkerF
              icon={{
                path: "m -3, 0 a 3,3 0 1,0 6,0 a 3,3 0 1,0 -6,0",
                scale: 2,
                fillColor: "red",
                fillOpacity: 1.0,
                strokeColor: "gold",
                strokeWeight: 3,
              }}
              key={index}
              position={mark}
            />
          ))}


          { /*(this.ob_capture === 1 || this.ob_done === 1)&&
            <PolylineF
              path={this.state.ob_coordinates}
              geodesic={true}
              
              options={{
                strokeColor: "#7fff00",
                strokeOpacity: 1,
                strokeWeight: 4,
                clickable: false
              }}
            /> */
          }

            
          {(this.ob_capture === 1 || this.ob_done === 1)  &&
            <Polygon
              paths={[this.state.ob_coordinates , this.state.ib_coordinates]}
              geodesic={true}
              options={{
                strokeColor: "#7fff00",
                fillColor: "purple",
                fillOpacity: 0.5,
                strokeOpacity: 1,
                strokeWeight: 4,  
                clickable: false                
              }}               
            />
          }          

          { /*this.ib_capture === 1  &&
            <PolygonF
              path={this.state.ib_coordinates1} 
              geodesic={true}
              clickable={false}
              options={{
                strokeColor: "#7fff00",
                fillColor: "purple",
                fillOpacity: 0.5,
                strokeOpacity: 1,
                strokeWeight: 4
              }}
              
            />
            */
          }        
   
        </GoogleMap>           
      </LoadScript>
    )
  }
}
export default DrawPath;
